import React from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import { FcAssistant, FcBusinessContact, FcHome } from "react-icons/fc";
import { GiBrazil } from "react-icons/gi";

const Contacts = () => {
  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Contato"
        background_image="faleconosco.png"
        subintro=""
        header={Header}
      ></Header>

      <div className="row pt-5 color_sobre pt-lg-0 pb-5 pb-lg-0 text-center d-flex justify-content-center">
        <div className="col-12">
          <h1 className="mb-5 font_size_sm text_left_sm text_left_lg mt-5">Fale com a mais valor</h1>
          <h2 className="mb-5 font_size_sm mt-5">
            Entre em contato com a gente e fale agora com os nossos consultores.
          </h2>
          <span className="mb-4 fw-bold fs-6 ">
            Disponível das 10h às 18h, exceto final de semana e feriados.
          </span>
        </div>
        <div className="d-lg-flex justify-content-center mt-3 ">
          <div id="ctt_local" className="col-xl-3 col-md-6 pt-4 pt-md-0">
            <FcHome size={80} />
            <address className="mt--2 mt-0 mb-0">
              <p>
                Rua Quinze de Novembro, 184
                <br />
                13° andar - Centro
                <br />
                São Paulo / SP
              </p>
            </address>
          </div>
          <div id="ctt_tel" className="col-xl-3 col-md-3 pt-4 pt-md-0">
            <GiBrazil size={80} color="green" />
            <p className="mt-md-2 mt-0 mb-0 ">
              Bolsa Família
            </p>
            <p className="mt-md-2 mt-0 mb-0 font_size">
              <a className="text-reset" href="/#">
                {" "}
                Sac 0800 080 0137
              </a>
            </p>
          </div>
          <div id="ctt_tel" className="col-xl-3 col-md-3 pt-4 pt-md-0">
            <FcAssistant size={80} />
            <p className="mt-md-2 mt-0 mb-0 ">
              Sede Adm. (11) 3467-0070
            </p>
            <p className="mt-md-2 mt-0 mb-0 font_size">
              <a className="text-reset" href="/#">
                {" "}
                Sac 0800-042-0710
              </a>
            </p>
          </div>
          <div
            id="ctt_email"
            className="col-xl-3 col-md-3 offset-xl-0 offset-md-0 pt-4 pt-xl-0"
          >
            <FcBusinessContact size={80} />
            <p className="m-0 font_size mt-md-2">
              <a className="text-reset" href="mailto:vagas@grupomaisvalor.com.br">
                vagas@grupomaisvalor.com.br
              </a>
            </p>
            <p className="m-0 font_size">
              <a className="text-reset" href="mailto:sac@grupomaisvalor.com.br">
                sac@grupomaisvalor.com.br
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id="ctt_alerta" className="container-fluid text-white bg-dark pb-3 pt-3">
        <div className="container text-center">
          <p className="m-0">
            Atenção! A Mais Valor não solicita depósito antecipado para a
            liberação do crédito.
          </p>
          <p className="m-0">
            Caso receba algum contato suspeito entre fale com a mais valor.
          </p>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

export default Contacts;

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);
