import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "./Cookies";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";

function Menu() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark background absolute-top"
      id="mainNav"
    >
      <div id="mainNavigation" className="mainNavigation ">
        <div className="d-lg-block d-flex align-center justify-content-between  ">
          <nav role="navigation">
            <div className="py-3 text-center ml-3  ">
              <Link aria-label="pagina home do site" to="/">
                <img
                  src={images["logo-mv-70x51.svg"]}
                  alt="logotipo grupomaisvalor"
                  className="invert logo_width_mobile"
                  style={{ width: "4rem" }} // Defina o tamanho desejado em pixels
                />
              </Link>
            </div>
            <MyAlert></MyAlert>
            {/* <div class="alert alert-warning alert-dismissible d-lg-flex align-items-center fade show">
            <div></div>
            <i class="fa-solid fa-triangle-exclamation"></i>
            <strong class="mx-2">Aviso!</strong> O GRUPO MAIS VALOR não solicita
            depósitos, transferências, adiantamentos ou cobranças de taxas para
            nenhum de seus clientes.
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
            ></button>
          </div> */}
            { }
          </nav>

          <div className="navbar-expand-md py-2">
            <div className="navbar-dark d-md-none text-center ">
              <Dropdown>
                <Dropdown.Toggle variant="white" className="w-100">
                  <span className="text-success">
                    <i
                      className="fa-solid fa-bars"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/">Home</Dropdown.Item>
                  <Dropdown.Item href="/produtos">Produtos</Dropdown.Item>
                  <Dropdown.Item href="/saqueaniversario">
                    Saque-Aniversário
                  </Dropdown.Item>
                  <Dropdown.Item href="/creditoconsignado">
                    Crédito Consignado
                  </Dropdown.Item>
                  <Dropdown.Item href="/creditocomgarantiadeimovel">
                    Crédito com Garantia de Imóvel
                  </Dropdown.Item>
                  <Dropdown.Item href="/creditocomgarantiadeveiculo">
                    Crédito com Garantia de Veículo
                  </Dropdown.Item>
                  <Dropdown.Item href="/consorcios">Consórcios</Dropdown.Item>
                  <Dropdown.Item href="/portabilidadeemprestimo">
                    Portabilidade de Empréstimos
                  </Dropdown.Item>
                  <Dropdown.Item href="/cartaobeneficio">
                    Cartão Benefício
                  </Dropdown.Item>
                  <Dropdown.Item href="/creditopessoal">
                    Crédito Pessoal
                  </Dropdown.Item>
                  <Dropdown.Item href="/sobrenos">Sobre Nós</Dropdown.Item>
                  <Dropdown.Item href="https://www.contatoseguro.com.br/grupomaisvalor">
                    Canal de Ética
                  </Dropdown.Item>
                  <Dropdown.Item href="/contatos">Fale com a mais valor</Dropdown.Item>
                  <Dropdown.Item href="https://carreirasmaisvalor.solides.jobs/">
                    Trabalhe Conosco
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div
            className="text-center collapse navbar-collapse"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav mx-auto ">
              <li className="nav-item">
                <Link className="nav-link fs-5" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown dropdown_hover ">
                <Link className="nav-link fs-5" to="/produtos">
                  Produtos
                </Link>

                <ul
                  className="dropdown-menu my-0"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="https://fgts.grupomaisvalor.com.br/" target="_blank" rel="noopener noreferrer">
                      Antecipação Saque-Aniversário FGTS
                    </a>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/cartaobeneficio">
                      Cartão Benefício
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/consorcios">
                      Consórcio
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      to="/creditocomgarantiadeimovel"
                    >
                      Crédito com Garantia de Imóvel
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/creditocomgarantiadeveiculo"
                    >
                      Crédito com Garantia de Veículo
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/creditoconsignado">
                      Crédito Consignado
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/creditopessoal">
                      Crédito Pessoal
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/portabilidadeemprestimo"
                    >
                      Portabilidade de Empréstimo
                    </Link>
                  </li>

                  <li >
                    <a className="dropdown-item" href="https://crefisabolsafamilia.grupomaisvalor.com.br/" target="blank">
                      Bolsa Família
                    </a>
                  </li>

                  <li >
                    <a className="dropdown-item" href="https://servidorpublico.grupomaisvalor.com.br/" target="blank">
                      Sevidor Público
                    </a>
                  </li>
                </ul>
              </li>
              <li id="Services" className="nav-item">
                <Link className="nav-link fs-5" to="/sobrenos">
                  Sobre Nós
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link fs-5" href="https://www.contatoseguro.com.br/grupomaisvalor">
                  Canal de Ética
                </a>
              </li>
              <li id="Contato" className="nav-item">
                <Link className="nav-link fs-5" to="/contatos">
                  Fale com a mais valor
                </Link>
              </li>
              <li id="Contato" className="nav-item">
                <a
                  href="https://carreirasmaisvalor.solides.jobs/"
                  target="_blank"
                  className="nav-link fs-5"
                  rel="noreferrer noopener"
                >
                  Trabalhe Conosco
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Cookies cookies={Cookies}></Cookies>
      </div>
    </nav>
  );
}
function MyAlert() {
  const alert_closed = window.sessionStorage.getItem("alert_closed");
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
      window.sessionStorage.setItem("alert_closed", true);
    }, 10000); // 10000 milissegundos = 10 segundos

    return () => clearTimeout(timeout);
  }, []);

  if (show && !alert_closed) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <div className="d-none d-md-flex justify-content-center align-items-center">
          <i className="fa-solid fa-triangle-exclamation"></i>
          <strong className="mx-2">Aviso!</strong> O GRUPO MAIS VALOR não
          solicita depósitos, transferências, adiantamentos ou cobranças de
          taxas para nenhum de seus clientes.
        </div>
        <div className="d-md-none">
          <i className="fa-solid fa-triangle-exclamation"></i>
          <strong className="mx-2">Aviso!</strong> O GRUPO MAIS VALOR não
          solicita depósitos, transferências, adiantamentos ou cobranças de
          taxas para nenhum de seus clientes.
        </div>
      </Alert>
    );
  } else {
    return null;
  }
}

// function MyAlert() {
//   const alert_closed = window.sessionStorage.getItem("alert_closed");
//   const [show, setShow] = useState(true);
//   const closeAlert = () => {
//     window.sessionStorage.setItem("alert_closed", true);
//     setShow(false);
//   };
//   if (show && !alert_closed) {
//     return (
//       <Alert variant="warning" onClose={closeAlert} dismissible>
//         <i className="fa-solid fa-triangle-exclamation"></i>
//         <strong className="mx-2">Aviso!</strong> O GRUPO MAIS VALOR não solicita
//         depósitos, transferências, adiantamentos ou cobranças de taxas para
//         nenhum de seus clientes.
//       </Alert>
//     );
//   }
// }

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);

export default Menu;
